import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import CaseStudyArea from '../containers/global/case-study-area'
import CTAArea from '../containers/global/cta-area/section-one'
import { useStaticQuery, graphql } from "gatsby"


const CityPage = ({ pageContext, location }) => {

   const cityData = useStaticQuery(graphql `
        query {            
            allAirtable(
                filter: {
                  table: { eq: "Cities" }
                }
              ) {
                edges {
                  node {
                    data {                      
                      Name
                      Slug
                      Note
                      Location : Region
                      Country
                      WorldRegion
                      Photo {
                          url
                      }
                    }
                  }
                }
            }
        }
    `)
    const cities = cityData.allAirtable.edges;

   const info = {
       buttonText : "View City",
       url : "city"
   }

   


     return(
      <Layout location={location}>
        <SEO title="Cities"/>
        <Header/>
          <CaseStudyArea data={cities} info={info} />
          <CTAArea/>
        <Footer/>
      </Layout>
    )}
 
export default CityPage
 